const Countries = () => (
  <div className="countries-wrapper info1">
    <h1>Country list</h1>
    <span>Citizens and companies from the following countries will not be eligible to take part in our services:</span>
    <br />
    <div className="country-list">
      <b>
        The United States, Balkans region, Belarus, Myanmar, Côte d’Ivoire, Cuba, Democratic Republic of the Congo,
        Iran, Iraq, Liberia, North Korea, People’s Republic of China, Hong Kong, Sudan, Syrian Arab Republic, Zimbabwe,
        Algeria, Bangladesh, Bolivia, Cambodia, Ecuador, Nepal, Afghanistan, Burundi, Central, African Republic,
        Malaysia, Ethiopia, Guinea, Guinea-Bissau, Lebanon, Sri Lanka, Libya, Serbia, Somalia, South Sudan, Tunisia,
        Trinidad and Tobago, Ukraine, Uganda, Venezuela, Yemen.
      </b>
    </div>
  </div>
);

export default Countries;
