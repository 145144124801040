import { useEffect, useMemo, useState } from "react";
import "./Team.scss";
import { Link } from "react-router-dom";

enum NewsCategories {
  markets = "Markets",
  web3 = "Web3",
  collab = "Collaboration",
}

//tuple [imageClassName, title, link, blurb, date, category]
const news = [
  [
    "scmp",
    "Maturing asset class driven by regulatory, institutional participation",
    "https://www.scmp.com/business/article/2140811/mature-cryptocurrency-market-draw-more-institutional-traders-year-analyst",
    "“If the regulatory stance gets clearer, large funds will be more assured and willing to commit significant capital” - Orichal Partners",
    "April 9, 2018",
    NewsCategories.markets,
  ],
  [
    "crew",
    "Real-world applications drive mass adoption in the sector",
    "https://techbullion.com/interview-with-orichal-partners-cryptocurrency-investment-and-blockchain-advisory-firm/",
    "“We should also see an increasing scale of mass adoption behind the layers that supports our daily and common activities” - Scottie Siu",
    "Oct 25, 2018",
    NewsCategories.markets,
  ],
  [
    "yuan",
    "Trade War and weaker Yuan spurs rise in cryptocurrency activity",
    "https://www.reuters.com/article/us-crypto-currencies-idUSKCN1V41PB",
    "“A lot of people trying to move capital in and out of borders try to use Tether as a medium,” - Anthony Wong",
    "Aug 15, 2019",
    NewsCategories.markets,
  ],
  [
    "losing",
    "Bitcoin set for record losing streak after ‘stablecoin’ collapse",
    "https://www.arabnews.com/node/2081346/business-economy",
    "“I anticipate further downside ahead, but if open interest drops, indicating speculators exit, then the market could stabilize.” - Scottie Siu",
    "May 13, 2022",
    NewsCategories.markets,
  ],
  [
    "selloff",
    "Bitcoin falls to fresh 18-month low as crypto meltdown deepens",
    "https://www.nbcnews.com/tech/crypto/bitcoin-falls-fresh-18-month-low-crypto-meltdown-deepens-rcna33678",
    "“The ripples running through the market haven’t stopped yet,” - Scottie Siu",
    "Jun 15, 2022",
    NewsCategories.markets,
  ],
  [
    "binancians",
    "Cryptocurrencies fall after FTX-Binance turmoil spooks investors",
    "https://www.reuters.com/markets/currencies/cryptos-attempt-steady-binance-ftx-deal-chills-market-2022-11-09/",
    "“What if the deal falls through, or [Binance] comes back to offer 10c on the dollar? That’s the market’s current blind spot - Scottie Siu",
    "Nov 9, 2022",
    NewsCategories.markets,
  ],
  [
    "vinai",
    "Orichal Advisory: DJ and Producer VINAI first NFT Release",
    "https://www.asiacryptotoday.com/dj-and-producer-vinai-announced-their-first-nft-release/",
    "“NFTs will lead the way on our next phase of blockchain adoption and can revolutionize the entertainment and social industries,” - Scottie Siu",
    "Apr 9, 2021",
    NewsCategories.web3,
  ],
  [
    "miami",
    "The Future of NFTs - DCentral Miami",
    "https://www.youtube.com/watch?v=Z9BA93o9BL8",
    "“NFTs, as tradable subscriptions, have the potential to support content creators and represent the future of Web 3.0.” - Scottie Siu",
    "Apr 9, 2021",
    NewsCategories.web3,
  ],
  [
    "chaos",
    "Orichal Advisory: Eric Chou x Wing Shya debut NFT collection",
    "https://cointelegraph.com/press-releases/eric-chou-x-wing-shya-make-history-with-debut-nft-collection/",
    "“More importantly, artists can collaborate with others and engage with their fan base through NFTs,” - Alex Dong of Sunny Side Up.",
    "Apr 16, 2021",
    NewsCategories.web3,
  ],
  [
    "scottie",
    "NFTs can transform music, enabling engagement and co-creation",
    "https://www.chinadaily.com.cn/a/202205/13/WS627def06a310fd2b29e5c85d.html",
    "“It is absolutely possible to have new ways to discover and promote rising talents by drawing communities together.” - Scottie Siu",
    "May 13, 2022",
    NewsCategories.web3,
  ],
  [
    "david",
    "Orichal Partners with Ripple’s Chief Cryptographer David Schwartz",
    "https://medium.com/orichal-partners/event-recap-orichal-partners-with-ripples-chief-cryptographer-david-schwartz-18e11c5aac96",
    "“There is definitely challenges in integration, but [Ripple] has been tightening up the cycle by working with more than 100 banks.” - David",
    "Apr 11, 2018",
    NewsCategories.collab,
  ],
  [
    "clement",
    "Orichal: Blockchain VIP Invitational at Consensus: Singapore 2018",
    "https://techbullion.com/orichal-partners-host-blockchain-vip-invitational-at-consensus-singapore-2018/",
    "“We wanted to give back to the community that has supported us... and helped us generate significant traction in the industry.” - Scottie",
    "Oct 5, 2018",
    NewsCategories.collab,
  ],
  [
    "atlantis",
    "Orichal: Leading the way in Crypto Investments & driving mass adoption",
    "https://thefintechtimes.com/orichal-partners/",
    "“Continue to foster the blockchain industry through our advisory and collaborative works with trusted partners across the field.” - Scottie",
    "Oct 22, 2018",
    NewsCategories.collab,
  ],
  [
    "axion",
    "AGAM secures regulatory approval to manage cryptocurrency funds",
    "https://finance.yahoo.com/news/axion-global-asset-management-secures-102809901.html?guccounter=1",
    "‘We saw the opportunity to inspire confidence of investors by combining crypto expertise with high governance standards.” - Scottie",
    "Jan 20, 2022",
    NewsCategories.collab,
  ],
];

const Team = () => {
  const [newsSelected, setNewsSelected] = useState<NewsCategories>(NewsCategories.markets);
  //lazy enum: 0 is markets, 1 is web3, 2 is collab

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderedNews = useMemo(() => news.filter((n) => n[5] === newsSelected), [newsSelected]);

  return (
    <div className="team-wrapper">
      <div className="journey">
        <h1>Our Journey</h1>
        <h2>
          At Orichal, we blend the worlds of traditional finance and digital assets. Founded by individuals who have
          witnessed the transformative power of breakthrough technologies, we embrace the evolving landscape of Web 3.0.
          Since 2017, we actively shape the digital asset industry, drawing from our deep expertise and experience in
          both financial and technological realms. Bridging the gap between the old and the new. Join us on this
          exciting journey as we guide you confidently into the future.
        </h2>
        {/* <div className="carousel-control">
          {carouselPosition > 0 && (
            <div className="arrowLeft" onClick={() => setCarouselPosition(carouselPosition - 1)}>
              {"<"}
            </div>
          )}
          <div className="carousel">
            <div className={"timelines pos" + carouselPosition}>
              <div className="timeline">
                <h1>2017</h1>
                <h2>ORICHAL FOUNDED</h2>
                <p>Orichal Partners is established by founding partners Anthony Wong and Scottie Siu.</p>
              </div>
              <div className="timeline">
                <h1>2018</h1>
                <h2>TBC</h2>
                <p>To be continued...</p>
              </div>
              <div className="timeline">
                <h1>2019</h1>
                <h2>TBC</h2>
                <p>To be continued...</p>
              </div>
              <div className="timeline">
                <h1>2020</h1>
                <h2>TBC</h2>
                <p>To be continued...</p>
              </div>
              <div className="timeline">
                <h1>2021</h1>
                <h2>TBC</h2>
                <p>To be continued...</p>
              </div>
              <div className="timeline">
                <h1>2022</h1>
                <h2>TBC</h2>
                <p>To be continued...</p>
              </div>
              <div className="timeline">
                <h1>2023</h1>
                <h2>TBC</h2>
                <p>To be continued...</p>
              </div>
            </div>
          </div>
          {carouselPosition < 4 && (
            <div className="arrowRight" onClick={() => setCarouselPosition(carouselPosition + 1)}>
              {">"}
            </div>
          )}
        </div> */}
      </div>
      <div className="whiteback">
        <h3 className="team">OUR TEAM</h3>
        <div className="team-members">
          <div className="team-member">
            <div className="portrait anthony">
              <div className="hoverblurb">
                Early Bitcoin and Ethereum investor. Experienced crypto trader with 6 years in directional trading,
                derivatives, and funding markets. Former JPMorgan Institutional Sales specialising in FX and rates
                solutions in North Asia.
              </div>
            </div>
            <h1>ANTHONY</h1>
            <h2>Partner</h2>
          </div>
          <div className="team-member">
            <div className="portrait scottie">
              <div className="hoverblurb">
                Early blockchain adopter, bitcoin, ethereum investor. Leads token advisory team in market making, web3
                and digital asset innovations. Former JPMorgan, Deutsche Bank analyst, experienced trader at tier-1
                hedge funds.
              </div>
            </div>
            <h1>SCOTTIE</h1>
            <h2>Partner</h2>
          </div>
          <div className="team-member">
            <div className="portrait yite">
              <div className="hoverblurb">
                Quant Engineer and Financial Quantitative Algorithm buidler, specialised in financial models and
                software development. Previous experience as a Computer Vision Engineer. Holds a degree in Mathematics
                and Statistics.
              </div>
            </div>
            <h1>YI TE</h1>
            <h2>Senior Quantitative Algo Engineer</h2>
          </div>
          <div className="team-member">
            <div className="portrait will">
              <div className="hoverblurb">
                Senior Software Engineer, specializing in buidling system architecture with highly performant
                scalability. Previous experience as a Backend Engineer. Holds a Master's in Computer Science.
              </div>
            </div>
            <h1>KE WEI</h1>
            <h2>Senior Software Engineer</h2>
          </div>
          <div className="team-member">
            <div className="portrait justin">
              <div className="hoverblurb">
                Crypto trading enthusiast experienced in Defi, DAPPS, and DEX for yield farming, staking, and arbitrage
                trading. Currently focused on investment research, portfolio management, and trading assistance.
              </div>
            </div>
            <h1>JUSTIN</h1>
            <h2>Trader & Analyst</h2>
          </div>
          <div className="team-member">
            <div className="portrait kirill">
              <div className="hoverblurb">
                Smart contract developer experienced in DeFi and AI/ML-powered NFT projects. Tech writer and web
                developer since 2010. Expertise in blockchain, AI/ML, cloud-native apps, and finance. Former "Tech
                Content Director" at Qbox Inc.
              </div>
            </div>
            <h1>KIRILL</h1>
            <h2>Smart Contract Engineer</h2>
          </div>
          <div className="team-member">
            <div className="portrait ian">
              <div className="hoverblurb">
                UX Engineer, Full Stack Developer, and former smartphone games designer. Built everything from casino
                touchscreen systems for Baccarat tables, to custom form creation and collection apps for the
                construction industry.
              </div>
            </div>
            <h1>IAN</h1>
            <h2>UX Engineer</h2>
          </div>
          <div className="team-member">
            <div className="portrait awday">
              <div className="hoverblurb">
                Experienced data professional driving AI implementation for Fin-tech. 5 years in data science and
                machine learning for global financial firms.
              </div>
            </div>
            <h1>AWDAY</h1>
            <h2>System Infrastructure Consultant</h2>
          </div>
          <div className="team-member">
            <div className="portrait awday">
              <div className="hoverblurb">
                Experienced operations manager overseeing day-to-day functions and facilitating the team.
              </div>
            </div>
            <h1>ELAINE</h1>
            <h2>Operations Manager</h2>
          </div>
        </div>
      </div>
      {/* <div className="blueback">
        <h3>OUR ADVISORS</h3>
        <div className="logo-parade-wrapper">
          <div className="logo-parade noback">
            <div className="logo high lylaw" />
            <div className="logo high coinbase wbg" />
            <div className="logo high sunnysideup" />
            <div className="logo high sunent" />
            <div className="logo high allbit" />
            <div className="logo high bitgosu" />
            <div className="logo high coinhako" />
            <div className="logo high coinsuper" />
            <div className="logo high coinsnetwork" />
            <div className="logo high dna" />
            <div className="logo high eventprotocol" />
            <div className="logo high fintnews" />
            <div className="logo high foundationx" />
            <div className="logo high gb" />
            <div className="logo high hikari" />
            <div className="logo high lylaw" />
            <div className="logo high wxygroup" />
          </div>
        </div>
      </div> */}
      <div className="whiteback">
        <h3>NEWSROOM</h3>
        <div className="toggles">
          <div
            className={"toggle" + (newsSelected === NewsCategories.markets ? " selected" : "")}
            onClick={() => setNewsSelected(NewsCategories.markets)}
          >
            <span>Markets</span>
          </div>
          <div
            className={"toggle" + (newsSelected === NewsCategories.web3 ? " selected" : "")}
            onClick={() => setNewsSelected(NewsCategories.web3)}
          >
            <span>Web3</span>
          </div>
          <div
            className={"toggle" + (newsSelected === NewsCategories.collab ? " selected" : "")}
            onClick={() => setNewsSelected(NewsCategories.collab)}
          >
            <span>Collaborations</span>
          </div>
        </div>
        <div className="cards">
          {renderedNews.map((mn, i) => (
            <div className="news">
              <div className={"card " + mn[0]} />
              <div className="info">
                <p className="category">{mn[5]}</p>
                <p className="date">{mn[4]}</p>
              </div>
              <h3 className="blue">
                <Link to={mn[2]}>{mn[1]}</Link>
              </h3>
              <h2 className="blurb">{mn[3]}</h2>
            </div>
          ))}
        </div>
        <button>SPEAK WITH US TODAY</button>
      </div>
    </div>
  );
};

export default Team;
