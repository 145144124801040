import { Link } from "react-router-dom";
import "./Footer.scss";
import { Modal } from "../Orichal";

type Props = {
  modalState: Modal;
};

function Footer(props: Props) {
  const { modalState } = props;

  return (
    <div className="footer-wrapper">
      <div className="left-wrapper">
        <div className="logo-footer" />
        <div className="connect">
          {modalState === Modal.None && (
            <div>
              <a key={"scottie.siu@orichal.io"} href={"scottie.siu@orichal.io"}>
                <div className="icon mail" />
              </a>
              <a key={"https://medium.com/orichal-partners"} href={"https://medium.com/orichal-partners"}>
                <div className="icon medium" />
              </a>
              <a key={"https://twitter.com/orichalpartners"} href={"https://twitter.com/orichalpartners"}>
                <div className="icon twitter" />
              </a>
            </div>
          )}
        </div>
        <div className="copyright">Copyright 2022 - 2023. Orichal Partners</div>
      </div>
      <div className="right-wrapper">
        <div className="footer-menu-col">
          <h1>EXPLORE</h1>
          <Link to={"/"}>Home</Link>
          <Link to={"/investment"}>Investments</Link>
          <Link to={"/advisory"}>Token Advisory</Link>
          <Link to={"/faq"}>FAQ</Link>
          <Link to={"/insights"}>Daily Market Reports</Link>
          <Link to={"/insights"}>Blogs & Research</Link>
        </div>
        <div className="footer-menu-col">
          <h1>ABOUT</h1>
          <Link to={"/team"}>About Us</Link>
          <Link to={"/tos"}>Terms of Service</Link>
          <Link to={"/privacy"}>Privacy Policy</Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;
