import { useState } from "react";
import "./faq.scss";

type QuestionsState = {
  0: boolean;
  1: boolean;
  2: boolean;
  3: boolean;
  4: boolean;
  5: boolean;
  6: boolean;
  7: boolean;
  8: boolean;
};

const Faq = () => {
  const [collapsed, setCollapsed] = useState<QuestionsState>({
    0: true,
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
    6: true,
    7: true,
    8: true,
  });

  return (
    <div className="faq-wrapper info1">
      <h1>Frequently Asked Questions</h1>
      <div className="columns">
        <div className="column left">
          <div className="question-wrapper">
            <div className="question">
              <span>What is market making?</span>
              <div className="plus" onClick={() => setCollapsed({ ...collapsed, 0: !collapsed[0] })}>
                {collapsed[0] ? "+" : "-"}
              </div>
            </div>
            <div className={"answer-wrapper" + (collapsed[0] ? " collapsed" : "")}>
              <div className={"answer" + (collapsed[0] ? " collapsed" : "")}>
                Market making involves supplying liquidity to capital markets, ensuring that there's adequate volume at
                fair prices for investors to trade tokens. Like a currency exchange kiosk, market makers submit buy and
                sell orders, profiting from the price difference, or spread. This practice isn't exclusive to the crypto
                realm; market making has been a long-standing financial strategy in traditional financial markets for
                many years. That’s where Orichal comes in.
              </div>
            </div>
          </div>
          <div className="question-wrapper">
            <div className="question">
              <span>What are common activities within your services?</span>
              <div className="plus" onClick={() => setCollapsed({ ...collapsed, 1: !collapsed[1] })}>
                {collapsed[1] ? "+" : "-"}
              </div>
            </div>
            <div className={"answer-wrapper" + (collapsed[1] ? " collapsed" : "")}>
              <div className={"answer" + (collapsed[1] ? " collapsed" : "")}>
                Orichal’s full-suite service offerings include token advisory, liquidity management, tokenomics design,
                strategic introductions, and exchange listing facilitation (we have experience across over 12+ global
                and local exchanges).
              </div>
            </div>
          </div>
          <div className="question-wrapper">
            <div className="question">
              <span>Is it possible to do market making on my own or use a trading bot?</span>
              <div className="plus" onClick={() => setCollapsed({ ...collapsed, 2: !collapsed[2] })}>
                {collapsed[2] ? "+" : "-"}
              </div>
            </div>
            <div className={"answer-wrapper" + (collapsed[2] ? " collapsed" : "")}>
              <div className={"answer" + (collapsed[2] ? " collapsed" : "")}>
                Top-tier crypto market-making firms utilize proprietary software, extensive development teams, and
                seasoned traders with years of industry expertise. Market making demands substantial investments in IT
                infrastructure, algorithm development, risk management policies, and access to working capital. While
                trading bots are often simple algorithms that may perform well on “good days” in underdeveloped markets,
                most of them are not robust or fast enough to compete with professional market makers.
              </div>
            </div>
          </div>
          <div className="question-wrapper">
            <div className="question">
              <span>Will market making aid in getting my token listed?</span>
              <div className="plus" onClick={() => setCollapsed({ ...collapsed, 3: !collapsed[3] })}>
                {collapsed[3] ? "+" : "-"}
              </div>
            </div>
            <div className={"answer-wrapper" + (collapsed[3] ? " collapsed" : "")}>
              <div className={"answer" + (collapsed[3] ? " collapsed" : "")}>
                Top-tier crypto market-making firms utilize proprietary software, extensive development teams, and
                seasoned traders with years of industry expertise. Market making demands substantial investments in IT
                infrastructure, algorithm development, risk management policies, and access to working capital. While
                trading bots are often simple algorithms that may perform well on “good days” in underdeveloped markets,
                most of them are not robust or fast enough to compete with professional market makers.
              </div>
            </div>
          </div>
          <div className="question-wrapper">
            <div className="question">
              <span>If my market liquidity has declined, can it be restored?</span>
              <div className="plus" onClick={() => setCollapsed({ ...collapsed, 4: !collapsed[4] })}>
                {collapsed[4] ? "+" : "-"}
              </div>
            </div>
            <div className={"answer-wrapper" + (collapsed[4] ? " collapsed" : "")}>
              <div className={"answer" + (collapsed[4] ? " collapsed" : "")}>
                Strong market demand stems from high-quality products and professional marketing, both crucial for
                long-term liquidity. However, many promising projects, particularly in their early stages or during
                “dips” and high volatility periods, may experience liquidity issues unrelated to the project’s merits.In
                such cases, market making can significantly boost your chances of restoring liquidity. Feel free to get
                in touch with us, and we can offer specific guidance based on your situation.
              </div>
            </div>
          </div>
        </div>
        <div className="column right">
          <div className="question-wrapper">
            <div className="question">
              <span>What are the key benefits of hiring a market maker?</span>
              <div className="plus" onClick={() => setCollapsed({ ...collapsed, 5: !collapsed[5] })}>
                {collapsed[5] ? "+" : "-"}
              </div>
            </div>
            <div className={"answer-wrapper" + (collapsed[5] ? " collapsed" : "")}>
              <div className={"answer" + (collapsed[5] ? " collapsed" : "")}>
                1. Improved liquidity: With market makers, people can easily buy and sell cryptocurrencies without long
                waiting times, making the market more attractive for traders and investors.
                <br />
                <br />
                2. Reduced price volatility: Market makers help to stabilize cryptocurrency prices by preventing large
                price swings. This is essential for the healthy growth of the market.
                <br />
                <br />
                3. Tighter spreads: Spreads are the difference between the buying and selling price of an asset. Market
                makers help to narrow these spreads, reducing trading costs for investors.
              </div>
            </div>
          </div>
          <div className="question-wrapper">
            <div className="question">
              <span>Is market making necessary for every listed token?</span>
              <div className="plus" onClick={() => setCollapsed({ ...collapsed, 6: !collapsed[6] })}>
                {collapsed[6] ? "+" : "-"}
              </div>
            </div>
            <div className={"answer-wrapper" + (collapsed[6] ? " collapsed" : "")}>
              <div className={"answer" + (collapsed[6] ? " collapsed" : "")}>
                Yes, since each token must have a liquid market to serve investors and the broader community. While the
                top 10-15 tokens can rely on market makers to provide liquidity without additional assistance, other
                coins require tailored support to avoid an illiquid market and dissatisfied investors. Even top-tier
                tokens often need liquidity support for specific exchanges or trading pairs. Market makers ensure market
                parameters (e.g., spread, volume) are suitable for attracting more sophisticated investors. For
                instance, a 5% spread on your token may draw a few retail investors, but no reasonable crypto hedge fund
                would trade.
              </div>
            </div>
          </div>
          <div className="question-wrapper">
            <div className="question">
              <span>How do you ensure my funds are safe?</span>
              <div className="plus" onClick={() => setCollapsed({ ...collapsed, 7: !collapsed[7] })}>
                {collapsed[7] ? "+" : "-"}
              </div>
            </div>
            <div className={"answer-wrapper" + (collapsed[7] ? " collapsed" : "")}>
              <div className={"answer" + (collapsed[7] ? " collapsed" : "")}>
                We take pride in our outstanding risk management. Our operating model ensures your assets are safe. We
                only operate market making inventory through a managed account, so that token issuers maintain greater
                control and security.
              </div>
            </div>
          </div>
          <div className="question-wrapper">
            <div className="question">
              <span>How do you charge for your services?</span>
              <div className="plus" onClick={() => setCollapsed({ ...collapsed, 8: !collapsed[8] })}>
                {collapsed[8] ? "+" : "-"}
              </div>
            </div>
            <div className={"answer-wrapper" + (collapsed[8] ? " collapsed" : "")}>
              <div className={"answer" + (collapsed[8] ? " collapsed" : "")}>
                Our fees are 100% transparent, depending on the duration and trading volume.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
