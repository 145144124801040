const FullTerms = () => (
  <div className="fullterms-wrapper info2">
    <h3>EFFECTIVE DATE: APRIL 5th 2023 12:00 AM GMT +8</h3>
    <h1>Terms of Service</h1>
    <h2>1. Acceptance of the Terms and Conditions.</h2>
    <p>
      1. Binding Agreement; Description. This Terms of Service (this “Agreement”) is a binding contract between you, an
      individual user (“User” or “you”), and Orichal Asset Management, Inc., including our affiliates and subsidiaries
      (“Orichal Partners,” “we,” “us,” or “our”) governing your use of www.orichal.io and any other websites and/or
      online services or properties owned and operated by Orichal Partners (collectively, the “Service”). BY ACCESSING
      OR USING THE SERVICE, YOU AGREE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THIS AGREEMENT. IF YOU DO
      NOT AGREE TO THE TERMS OF THIS AGREEMENT, THEN YOU MAY NOT USE THE SERVICE. If you are accessing the Service on
      behalf of a business or corporate entity (“Organization”), then you hereby represent and warrant that you have the
      authority to bind that Organization and your acceptance of this Agreement will be treated as acceptance by the
      Organization. In that event, “User,” “you” and “your” in this Agreement will refer to the Organization.
    </p>
    <p>
      2. Material Terms and Notices. As provided in greater detail in this Agreement (and without limiting the express
      language of this Agreement), you acknowledge the following:
    </p>
    <ol>
      <li>
        The Service is licensed to you, not sold to you, and you may use the Service only as set forth in this
        Agreement;
      </li>
      <li>
        The use of the Service may be subject to separate third-party terms of service and fees, which are your sole
        responsibility;
      </li>
      <li>
        You consent to the collection, use, and disclosure of your personally identifiable information in accordance
        with Orichal’s Privacy Policy (“Privacy Policy”);
      </li>
      <li>
        To the fullest extent permitted by law, the Service is provided “as is” without warranties of any kind and
        Orichal’s liability to you is limited; and
      </li>
      <li>
        We will resolve disputes arising under this Agreement through binding arbitration. By accepting this Agreement,
        as provided in greater detail in Section 8 of this Agreement, you and Orichal are each waiving the right to a
        trial by jury or to participate in a class action.
      </li>
    </ol>
    <p>
      3. Changes to this Agreement. You understand and agree that Orichal Partners may change this Agreement at any time
      without prior notice; provided that Orichal Partners will endeavor to provide you with prior notice of any
      material changes. You may read a current, effective copy of this Agreement at any time by selecting the
      appropriate link on the Service. The revised Agreement will become effective at the time of posting. Any use of
      the Service after such date will constitute your acceptance of such revised Agreement. If any change to this
      Agreement is not acceptable to you, then your sole remedy is to stop accessing, browsing, and otherwise using the
      Service. The terms of this Agreement will govern any updates Orichal Partners provides to you that replace and/or
      supplement any portion of the Service, unless the upgrade is accompanied by a separate license or revised
      Agreement, in which case the terms of that license or revised Agreement will govern. Notwithstanding the preceding
      sentences of this Section 1.c, no revisions to this Agreement will apply to any dispute between you and Orichal
      Partners that arose prior to the effective date of such revision.
    </p>
    <p>
      4. Privacy Policy. Your access to and use of the Service is also subject to Orichal’s Privacy Policy located at
      www.orichal.com/privacy (“Privacy Policy”), the terms and conditions of which are incorporated herein by
      reference.
    </p>
    <p>
      5. Consideration. You understand and agree that this Agreement is entered into in consideration of your use of the
      Service and other good and valuable consideration, the receipt and sufficiency of which are hereby acknowledged.
    </p>
    <p>
      6. Eligibility. THE SERVICE IS NOT FOR PERSONS UNDER THE AGE OF 18 OR FOR ANY USERS PREVIOUSLY SUSPENDED OR
      REMOVED FROM THE SERVICE BY Orichal Partners. IF YOU ARE UNDER 18 YEARS OF AGE, THEN YOU MUST NOT USE OR ACCESS
      THE SERVICE AT ANY TIME OR IN ANY MANNER. By accessing or using the Service, you affirm that you are at least 18
      years of age.
    </p>
    <h2>2. The Service.</h2>
    <p>
      1. Description. The Service is intended to provide information regarding products and services offered by Orichal,
      its affiliates and subsidiaries to sophisticated potential investors. Such products and services may include, for
      example, private funds (“Funds”), public ETFs (“ETFs”), indexes (“Indexes”), strategies (“Strategies”) and other
      investment products (“Other Products” and, together with Funds, ETFs, Indexes and Strategies, the ”Orichal
      Products”). The Service may allow Users to engage with Orichal Products through certain activities, which may
      include: the submitting an indication of interest in a Orichal Product using a designated form (an “Investor
      Information Request Form”), executing related membership agreements, and tracking certain Orichal Product
      performance through the password-protected area of the Service intended for approved investors in certain Orichal
      Products (“Investors”) (such password-protected area of the Service is the “Investor Portal”). More information
      about certain Orichal Products is available at www.orichal.io/investment.
    </p>
    <p>
      2. Disclosures. By submitting an Investor Information Request Form, you represent and warrant that you have read
      and understood the disclosures regarding the Funds located at www.Orichalinvestments.com/disclosures, or other
      disclosures made available via the Service regarding other Orichal Products, which are hereby incorporated by
      reference. Orichal reserves the right to reject any application for membership and Investor Information Request
      Form at any time for any reason or for no reason at all and without notice to you. TO THE FULLEST EXTENT PERMITTED
      BY LAW, Orichal DISCLAIMS LIABILITY FOR ANY USER’S RELIANCE ON THE SERVICE TO INFORM AN INVESTMENT DECISION WITH
      RESPECT TO THE FUND.
    </p>
    <p>
      3. Fund Membership. The Funds’ subscription agreement (the “Subscription Agreement”) and other Fund documentation
      may be provided to you by Orichal at its sole discretion and only upon satisfactory completion of an Investor
      Information Request Form. This Agreement does not govern your participation in the Fund, which may only be
      effected by (i) following the applicable enrolment procedure; (ii) executing the Subscription Agreement and other
      required documentation; and (iii) acceptance of your membership in the Fund by Orichal Partners. Orichal Partners
      reserves the right to refuse or accept any subscription under the Subscription Agreement in its sole discretion.
    </p>
    <h2>3. Registration.</h2>
    <p>
      1. Login Credentials. While you may always browse the public-facing pages of the Service without registering, in
      order to see the status of your investment, including any available performance information, you must create an
      account with the Service (an “Account”), which enables you to access the Investor Portal. You may only create an
      Account and access the Investor Portal once you have been invited to do so by Orichal Partners in its sole
      discretion.
    </p>
    <p>
      2. Account Security. You are responsible for the security of your Account, and are fully responsible for all
      activities that occur through the use of your credentials. You will notify Orichal Partners immediately at
      info@orichal.io if you suspect or know of any unauthorized use of your login credentials or any other breach of
      security with respect to your Account. Orichal Partners will not be liable for any loss or damage arising from
      unauthorized use of your credentials prior to you notifying Orichal of such unauthorized use or loss of your
      credentials. Separate login credentials may be required to access External Sites (as defined in Section 6 below).
    </p>
    <p>
      3. Accuracy of Information. When creating an Account, you agree to provide true, accurate, current, and complete
      information as Orichal Partners requires. You will update the information about yourself promptly, and as
      necessary, to keep it current and accurate. We reserve the right to disallow, cancel, remove, or reassign certain
      usernames and permalinks in appropriate circumstances, as determined by us in our sole discretion, and may, with
      or without prior notice, suspend or terminate your Account if activities occur on your Account which, in our sole
      discretion, would or might constitute a violation of this Agreement, or an infringement or violation of any third
      party rights, or of any applicable laws or regulations. If messages sent to the email address you provide are
      returned as undeliverable, then Orichal Partners may terminate your Account immediately without notice to you and
      without any liability to you or any third party.
    </p>
  </div>
);

export default FullTerms;
