import { useEffect } from "react";
import "./Investment.scss";
import { Modal, ModalProps } from "../Orichal";
import { Link } from "react-router-dom";

type Props = {
  setModal: React.Dispatch<React.SetStateAction<ModalProps>>;
  seenInvestmentModal: boolean;
};

const Investment = (props: Props) => {
  const { setModal, seenInvestmentModal } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    !seenInvestmentModal &&
      setModal({
        state: Modal.InvestmentTerms,
      });
  }, [setModal, seenInvestmentModal]);

  return (
    <div className="investment-wrapper">
      <div className="blueback">
        <h3>
          OPPORTUNITIES IN DIGITAL
          <br className="mobileOnly" />
          ASSET CLASS
        </h3>
        <h1>
          The Time To Invest In
          <br /> Digital Assets Is Now
        </h1>
        <h2>
          In recent years, digital assets have become an increasingly popular asset class for portfolio diversification.
          <br className="desktopOnly" />
          With a maturing and evolving infrastructure, fueled by growing investment capital and widespread technological
          adoption,
          <br className="desktopOnly" />
          reputable market players are driving the growth and potential of this dynamic landscape.
        </h2>
      </div>
      {seenInvestmentModal && (
        <div className="blurbs">
          <div className="blurb left">
            <h3>Our Investment Philosophy</h3>
            <span>
              Successful investments are founded on forward-looking insights and an understanding of where the
              underlying risk and rewards are. Digital assets have promising opportunities and potentials, but they also
              come with a category of risks that needs to be carefully managed.
            </span>
            <div className="read-more">
              <Link to={"/team"}>READ MORE</Link>
            </div>
          </div>
          <div className="blurb right">
            <h3>Insight, Strategy, Action</h3>
            <span>
              Digital assets is a complex space with the amount of data and information accessible and shared online and
              on-chain. You can leverage our deep expertise in the crypto industry and network. We’ll make it simple.
            </span>
            <div className="read-more">
              <Link to={"/insights"}>READ MORE</Link>
            </div>
          </div>
        </div>
      )}
      <div className="investments-inner-wrapper">
        <h3>INVESTMENTS</h3>
        <h1>
          Capitalize on the emerging opportunities in
          <br />
          Digital Assets & Web3{" "}
        </h1>
        <span>
          Embark on your digital asset journey with Orichal's managed portfolios. With decades of experience in
          traditional
          <br />
          finance and digital assets, our team is trusted with their expertise. Access our range of digital asset
          strategies and seize
          <br /> opportunities in this growing asset class with confidence today.
        </span>
        <div className="investment-cards">
          <div className="investment-card">
            <h2>Directional Long/Short</h2>
            <h3>Alpha Momentum Portfolio</h3>
            <p>
              Our <b>opportunistic</b> and <b>quantitative</b> approach targets short to mid-term movements across the
              top 100 cryptocurrencies by market capitalization, capturing momentum opportunities.
            </p>
          </div>
          <div className="investment-card">
            <h2>Long Only</h2>
            <h3>Smart Beta Liquidity Portfolio</h3>
            <p>
              Our <b>macro fundamental</b> and <b>factor investing</b> approach targets emerging, developed, and liquid
              digital assets among the top 50 cryptocurrencies with risk-adjusted exposure.
            </p>
          </div>
          <div className="investment-card">
            <h2>Yield-Based</h2>
            <h3>Enhanced Income Fund Portfolio</h3>
            <p>
              Our <b>delta-neutral</b> and <b>opportunistic</b> approach drives a yield-based strategy, delivering
              stable and risk-adjusted returns across CeFi and DeFi platforms.
            </p>
          </div>
        </div>
        <div className="stats-wrapper">
          <h3>PERFORMANCE HISTORY</h3>
          <div className="stats fullwidth tightgap">
            <div className="stat smallp">
              <p>Deployed Investment</p>
              <h1>$150M+</h1>
            </div>
            <div className="divider" />
            <div className="stat smallp">
              <p>CAGR</p>
              <h1>18%+</h1>
            </div>
            <div className="divider" />
            <div className="stat smallp">
              <p style={{ height: 30, marginBottom: 15 }}>
                Average Portfolio Age
                <br />
                (since inception)
              </p>
              <h1>3.8+ yrs</h1>
            </div>
            <div className="divider" />
            <div className="stat smallp">
              <p>Crypto Industry Experience</p>
              <h1>6+ yrs</h1>
            </div>
            <div className="divider" />
            <div className="stat smallp">
              <p>Collective Trading Experience</p>
              <h1>30+ yrs</h1>
            </div>
          </div>
        </div>
        <button onClick={() => setModal({ state: Modal.Contact })}>SPEAK WITH US TODAY</button>
      </div>
    </div>
  );
};

export default Investment;
