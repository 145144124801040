import { BrowserRouter, Route, Routes } from "react-router-dom";
import Advisory from "./advisory/Advisory";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import Home from "./home/Home";
import Insights from "./insights/Insights";
import Investment from "./investment/Investment";
import Team from "./team/Team";
import { useState } from "react";
import Countries from "./countries/Countries";
import Privacy from "./privacy/Privacy";
import FullTerms from "./fullterms/FullTerms";
import Faq from "./faq/faq";

export enum Modal {
  None = 0,
  InvestmentTerms = 1,
  AdvisoryTerms = 2,
  Contact = 3,
}

export type ModalProps = {
  state: Modal;
  txn?: string;
  status?: string;
  message?: string;
};

//launch comment: July 14th, 2023

function Orichal() {
  const [modal, setModal] = useState<ModalProps>({ state: Modal.None });
  const [seenInvestmentModal, setSeenInvestmentModal] = useState<boolean>(false);
  const [seenAdvisoryModal, setSeenAdvisoryModal] = useState<boolean>(false);

  const layout = (elements: JSX.Element[]) => [
    <Header
      key="header"
      modal={modal}
      setModal={setModal}
      setSeenInvestmentModal={setSeenInvestmentModal}
      setSeenAdvisoryModal={setSeenAdvisoryModal}
    />,
    ...[elements],
  ];

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={layout([<Home key="home" setModal={setModal} />])} />
        <Route path="/insights" element={layout([<Insights key="insights" />])} />
        <Route
          path="/investment"
          element={layout([
            <Investment key="investment" setModal={setModal} seenInvestmentModal={seenInvestmentModal} />,
          ])}
        />
        <Route
          path="/advisory"
          element={layout([<Advisory key="advisory" setModal={setModal} seenAdvisoryModal={seenAdvisoryModal} />])}
        />
        <Route path="/team" element={layout([<Team key="team" />])} />
        <Route path="/faq" element={layout([<Faq key="faq" />])} />
        <Route path="/countries" element={layout([<Countries key="countries" />])} />
        <Route path="/tos" element={layout([<FullTerms key="tos" />])} />
        <Route path="/privacy" element={layout([<Privacy key="privacy" />])} />
      </Routes>
      <Footer modalState={modal.state} />
    </BrowserRouter>
  );
}

export default Orichal;
