const Privacy = () => (
  <div className="privacy-wrapper info2">
    <h3>EFFECTIVE DATE: APRIL 5, 2023 12:00 AM GMT+8</h3>
    <h1>Privacy Policy</h1>
    <p>
      This Privacy Policy governs the information Orichal Partners collects on its websites and through its online
      services (the “Service”). This Privacy Policy is incorporated by reference into the Orichal Partners Terms of
      Service (the “Terms”). The terms “Orichal Partners,” “we,” “us,” and “our” include Orichal Partners and our
      affiliates and subsidiaries. Any defined terms used and not defined herein shall have the meanings given to such
      terms in the Terms. This Privacy Policy explains how Orichal Partners may collect, use, and disclose information
      we obtain through the Service.
    </p>
    <br />
    <h2 className="big">When Orichal Partners Collects Your Information</h2>
    <h2>WHEN SUBMITTING AN INVESTOR INFORMATION REQUEST FORM</h2>
    <p>
      If you submit an Investor Information Request Form through the Service, then you must provide us with certain
      information, such as your name, country, email address, and investor accreditation status.
    </p>
    <h2>REGISTRATION</h2>
    <p>
      If you register an Account on the Service, then you must provide us with certain information, such as your name,
      address, and contact email address. Additionally, as a part of the subscription process, we may collect certain
      other information from you, such as your tax ID number, social security number, and information concerning your
      financial history, income, and net worth. If you are accessing the Service on behalf of an Organization, you may
      be required to provide the Organization’s certificate of incorporation and date of formation.
    </p>
    <h2>IN CONNECTION WITH AN INVESTMENT</h2>
    <p>
      In order to process payments of amounts you invest in the Fund, we collect certain payment information, including
      your bank routing numbers, bank account numbers, and billing address.
    </p>
    <h2>USING THE SERVICE</h2>
    <p>
      We collect information about your use of the Service. For example, we collect the information that you provide to
      us in connection with accessing the Investor Portal to view the performance of the Fund or your investment.
    </p>
    <h2>COMMUNICATIONS</h2>
    <p>
      If you contact us directly, we may receive additional information about you. For example, when you contact us
      through our general investor email address, we may receive your name, email address, phone number, the contents of
      a message or attachments that you may send to us, and other information you choose to provide.
    </p>
    <h2>LOCATION INFORMATION</h2>
    <p>
      We collect and store your location information if you enable your device to send it to us, such as by use of
      geolocation features in the device(s) through which you access the Service, or by inference from other information
      we collect (for example, your IP address indicates the general geographic region from which you are connecting to
      the Internet).
    </p>
    <h2>COOKIES, AUTOMATIC DATA COLLECTION, AND RELATED TECHNOLOGIES</h2>
    <p>
      The Service collects and stores information that is generated automatically as you use it, including your
      preferences and anonymous usage statistics.
      <br />
      <br />
      When you visit the Service, we and our third-party service providers receive and record information on our server
      logs from your browser, including your IP address, and from cookies and similar technology. Our service providers
      may collect information about your online activities over time and across different online services. Cookies are
      small text files placed on your computer browser when you visit a website. Most browsers allow you to block and
      delete cookies. However, if you do that, then the Service may not work properly.
      <br />
      <br /> We work with analytics providers who may use cookies and similar technologies to collect and analyze
      information about the use of the Service and report on activities and trends. These services may also collect
      information regarding the use of other websites, apps, and online resources. One of the service providers that we
      use for data analytics is Google Analytics. If you do not want Google Analytics to collect and use information
      about your use of the Service, then you can install an opt-out in your web browser
      (https://tools.google.com/dlpage/gaoptout/). <br />
      <br />
      By using the Service, you are authorizing us to gather, parse, and retain data related to the provision of the
      Service.
    </p>
    <h2 className="big">When Orichal Partners Uses Your Information</h2>
    <h2>INTERNAL AND SERVICE-RELATED USAGE</h2>
    <p>
      We use information, including unique identifiers and your location for internal and service-related purposes, such
      as data analytics, and may share this information with service providers to allow us to provide, maintain, and
      improve the Service.
    </p>
    <h2>COMMUNICATIONS</h2>
    <p>
      We may send emails to the email address you provide to us to verify your account and for informational and
      operational purposes, such as account management, customer service, system maintenance, or to inform you about
      additional products or services that may be of interest to you.
    </p>
    <h2>AGGREGATE DATA</h2>
    <p>We may anonymize and aggregate data collected through the Service and use it for any purpose.</p>
    <h2>LEGAL RIGHTS</h2>
    <p>We may use the information collected through the Service to enforce our Terms or other legal rights.</p>
    <h2 className="big">When Orichal Partners Uses Your Information</h2>
    <h2>INTERNAL AND SERVICE-RELATED USAGE</h2>
    <p>
      We use information, including unique identifiers and your location for internal and service-related purposes, such
      as data analytics, and may share this information with service providers to allow us to provide, maintain, and
      improve the Service.
    </p>
    <h2>COMMUNICATIONS</h2>
    <p>
      We may send emails to the email address you provide to us to verify your account and for informational and
      operational purposes, such as account management, customer service, system maintenance, or to inform you about
      additional products or services that may be of interest to you.
    </p>
    <h2>AGGREGATE DATA</h2>
    <p>We may anonymize and aggregate data collected through the Service and use it for any purpose.</p>
    <h2>LEGAL RIGHTS</h2>
    <p>We may use the information collected through the Service to enforce our Terms or other legal rights.</p>
    <h2 className="big">How Orichal Partners May Disclose Your Information</h2>
    <h2>WE USE VENDORS AND SERVICE PROVIDERS</h2>
    <p>
      We may share any information that we receive through the Service with vendors and service providers retained in
      connection with the provision of the Service, including our payment processors, auditors, and other vendors for
      data analytics purposes.
    </p>
    <h2>AS REQUIRED BY LAW AND SIMILAR DISCLOSURES</h2>
    <p>
      We may access, preserve, and disclose your information and/or other account information or content if we believe
      doing so is required or appropriate to: (i) comply with law enforcement requests and legal processes, such as a
      court order or subpoena; (ii) enforce this Privacy Policy or other agreements; (iii) respond to your requests; or
      (iv) protect your, our, or others’ rights, property, or safety.
    </p>
    <h2>MERGER, SALE, OR OTHER ASSET TRANSFERS</h2>
    <p>
      If we are involved in a merger, acquisition, financing due diligence, reorganization, bankruptcy, receivership,
      sale of assets, or transition of service to another provider, then your information may be sold or transferred as
      part of such a transaction as permitted by law and/or contract. The use of your information following any of the
      foregoing events will be governed by the provisions of the Privacy Policy in effect at the time Orichal Partners
      collected such information. We may also share your information within our group of companies.
    </p>
    <h2>WITH YOUR CONSENT</h2>
    <p>We may also disclose your information with your permission.</p>
    <h2 className="big">Security of Your Information</h2>
    <p>
      We take steps to ensure that your information is treated securely and in accordance with this Privacy Policy.
      Unfortunately, the Internet cannot be guaranteed to be 100% secure, and we cannot ensure or warrant the security
      of any information you provide to us.
    </p>
    <h2 className="big">Do Not Track</h2>
    <p>
      Some web browsers incorporate a “Do Not Track” feature. Because there is not yet an accepted standard for how to
      respond to Do Not Track signals, our website does not currently respond to such signals.
      <br />
      <br /> By using the Service or providing information to us, you agree that we may communicate with you
      electronically regarding security, privacy, and administrative issues relating to your use of the Service. If we
      learn of a security system’s breach, we may attempt to notify you electronically by posting a notice on the
      Service or sending an e-mail to you. You may have a legal right to receive this notice in writing. To receive free
      written notice of a security breach (or to withdraw your consent from receiving electronic notice), please notify
      us at info@orichal.io.
    </p>
    <h2 className="big">Children’s Privacy</h2>
    <p>
      We do not knowingly collect, maintain, or use personal information, as defined by the Federal Trade Commission’s
      Children’s Online Privacy Protection Act (“COPPA”) Rule, from children under 13 years of age, and no part of the
      Service is directed to children under the age of 13. If we learn that we have collected any personal information
      from children under 13, then we will promptly take steps to delete such information and terminate the child’s
      account.
    </p>
    <h2 className="big">International Users</h2>
    <p>
      If you visit the Service from the European Union or other regions with laws governing data collection and use,
      then please note that you are agreeing to the transfer of your information to the United States and processing
      globally. By providing your information to the Service, you consent to any transfer and processing in accordance
      with this Privacy Policy.
    </p>
    <h2 className="big">Changes to Our Privacy Policy and Practices</h2>
    <h2>POSTING OF REVISED PRIVACY POLICY</h2>
    <p>
      We will post any adjustments to the Privacy Policy on this web page, and update the “Effective Date” above. If you
      are concerned about how your information is used, then bookmark this page and read this Privacy Policy
      periodically. Your continued use of the Service after changes to the Privacy Policy constitutes your acceptance of
      the new terms of the Privacy Policy.
    </p>
    <h2>NEW USES OF INFORMATION</h2>
    <p>
      From time to time, we may desire to use information for uses not previously disclosed in our Privacy Policy. If
      our practices change regarding previously collected information in a way that would be materially less restrictive
      than stated in the version of this Privacy Policy in effect at the time we collected the information, then we will
      make reasonable efforts to provide notice and obtain consent to any such uses as may be required by law.
    </p>
    <h2 className="big">Contact Information</h2>
    <p>
      Orichal Partners Asset Management, Inc.
      <br />
      <br /> info@orichal.io
    </p>
  </div>
);

export default Privacy;
