import { useEffect } from "react";
import Blog from "../blog";
import "./Insights.scss";

const Insights = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="insights-wrapper">
      <div className="toggles">
        <div className="toggle selected">
          <p>BLOGS & RESEARCH</p>
        </div>
        {/* <div className="toggle selected">
          <p>DAILY MARKET REPORTS</p>
        </div> */}
      </div>
      <Blog />
    </div>
  );
};

export default Insights;
