import React, { useState } from "react";
import { Modal, ModalProps } from "../Orichal";
import { Link } from "react-router-dom";

type Props = {
  modalState: Modal;
  setModal: React.Dispatch<React.SetStateAction<ModalProps>>;
  setSeenInvestmentModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSeenAdvisoryModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const InvestmentPageOptions: [string, string, string][] = [
  ["INSTITUTIONAL INVESTOR", "I'm looking for investment strategies and research in digital assets.", "ii"],
];

const AdvisoryPageOptions: [string, string, string][] = [
  ["TOKEN PROJECT", "I'm looking for token liquidity management services to support my project.", "tp"],
  [
    "INSTITUTIONAL COMPANY",
    "I'm looking for innovative solutions and advisory services on blockchain or digital assets initiatives.",
    "ia",
  ],
];

function TermsModal(props: Props) {
  const { modalState, setModal, setSeenInvestmentModal, setSeenAdvisoryModal } = props;

  const [selectedIdx, setSelectedIdx] = useState<number>(-1);

  return (
    <div className="modal terms-of-service">
      {/* <div
        className="ex"
        onClick={() => {
          modalState === Modal.InvestmentTerms && setSeenInvestmentModal(true);
          modalState === Modal.AdvisoryTerms && setSeenAdvisoryModal(true);
          setModal({ state: Modal.None });
        }}
      >
        &#128938;
      </div> */}
      <h1>Welcome to Orichal Investments</h1>
      <h3>Choose your investor type</h3>
      <div className="investors">
        {(modalState === Modal.InvestmentTerms ? InvestmentPageOptions : AdvisoryPageOptions).map((o, i) => (
          <div
            className={"investor " + (selectedIdx === i ? "selected" : "")}
            onClick={() => setSelectedIdx(i)}
            key={i}
          >
            <div className={"icon " + o[2]} />
            <div className="text">
              <h2>{o[0]}</h2>
              <span>{o[1]}</span>
            </div>
          </div>
        ))}
      </div>
      <span className="modal-title">Please confirm the following by clicking "I AGREE":</span>
      <section>
        <p>
          I confirm that I am an Institutional Investor or Investment Consultant, a non-resident or non-citizen of the
          following countries - see list{" "}
          <Link className="link" to={"/countries"} onClick={() => setModal({ state: Modal.None })}>
            here
          </Link>
          . Residents and citizens from these countries will not be eligible for our services. Please refer to the full
          disclaimer{" "}
          <Link className="link" to={"/tos"} onClick={() => setModal({ state: Modal.None })}>
            here
          </Link>
          .
        </p>
      </section>
      <div className="button-centerer">
        <button
          disabled={selectedIdx === -1}
          onClick={() => {
            modalState === Modal.InvestmentTerms && setSeenInvestmentModal(true);
            modalState === Modal.AdvisoryTerms && setSeenAdvisoryModal(true);
            setModal({ state: Modal.None });
          }}
        >
          I AGREE
        </button>
      </div>
    </div>
  );
}

export default TermsModal;
