import React, { useEffect, useState } from "react";
import { Modal, ModalProps } from "../Orichal";
import emailjs from "emailjs-com";

type Props = {
  setModal: React.Dispatch<React.SetStateAction<ModalProps>>;
};

const SERVICE_ID = "service_kdiqx57";
const TEMPLATE_ID = "template_sxk4v2n";
const PUBLIC_KEY = "Imqm6-0iwlCeviRsm";

function ContactModal(props: Props) {
  const { setModal } = props;

  //these are just for validations
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");

  const [validations, setValidations] = useState<boolean[]>([true, true, true]);

  useEffect(() => {
    console.log(validations);
  }, [validations]);

  const sendForm = (e: any) => {
    e.preventDefault();
    setValidations([firstName === "" ? false : true, lastName === "" ? false : true, phone === "" ? false : true]);
    if (firstName !== "" && lastName !== "" && phone !== "") {
      emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY).then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
      e.target.reset();
      setModal({ state: Modal.None });
    }
  };

  return (
    <div className="modal contact styled-scrollbars">
      <div className="ex" onClick={() => setModal({ state: Modal.None })}>
        &#128941;
      </div>
      <h1>
        Let's connect <br className="mobileOnly" /> one-on-one.
      </h1>
      <span>Every client is unique, so we tailor our services to your needs and goals.</span>
      <br />
      <span>Please share a few details below and one of our specialists will reach out shortly.</span>
      <form action="mailto:ian.woo@orichal.io" onSubmit={sendForm}>
        <div className="field mobfullwidth">
          <label>First Name *</label>
          <input
            type="text"
            id="fname"
            name="fname"
            placeholder="First Name"
            onChange={(e) => setFirstName(e.target.value)}
          />
          {!validations[0] ? <span className="validation">* Required field.</span> : null}
        </div>
        <div className="field r mobfullwidth">
          <label>Last Name *</label>
          <input
            type="text"
            id="lname"
            name="lname"
            placeholder="Last Name"
            onChange={(e) => setLastName(e.target.value)}
          />
          {!validations[1] ? <span className="validation">* Required field.</span> : null}
        </div>
        <div className="field mobfullwidth">
          <label>Phone *</label>
          <input type="text" id="phone" name="phone" placeholder="Phone" onChange={(e) => setPhone(e.target.value)} />
          {!validations[2] ? <span className="validation">* Required field.</span> : null}
        </div>
        <div className="field r mobfullwidth">
          <label>Firm</label>
          <input type="text" id="firm" name="firm" placeholder="Firm" />
        </div>
        <div className="field mobfullwidth">
          <label>Title</label>
          <input type="text" id="title" name="title" placeholder="Title" />
        </div>
        <div className="field r mobfullwidth">
          <label>Profile</label>
          <select id="profile" name="profile">
            <option value="Token Project">Token Project</option>
            <option value="Blockchain Company">Blockchain Company</option>
            <option value="Institutional/Accredited Investor">Institutional/Accredited Investor</option>
            <option value="Other">Other(s)</option>
          </select>
        </div>
        <div className="field fullwidth mobfullwidth">
          <label>Do you have a deployment timeframe?</label>
          <select id="timeframe" name="timeframe">
            <option value="0 - 3 Months">0 - 3 Months</option>
            <option value="3 - 12 Months">3 - 12 Months</option>
            <option value="12+ Months">12+ Months</option>
            <option value="Not sure">Not sure</option>
          </select>
        </div>
        <div className="field fullwidth mobfullwidth">
          <label>How can we help you?</label>
          <textarea
            id="body"
            name="body"
            className="textbox"
            aria-multiline="true"
            contentEditable
            placeholder="Please type here..."
          />
          <p>
            This form is intended to be used by prospective clients to seek information.
            <br />
            If you are an existing client, please do not use this form.
          </p>
          <button className="submit-button" type="submit" value="Send">
            SUBMIT
          </button>
        </div>
      </form>
    </div>
  );
}

export default ContactModal;
